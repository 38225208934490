module.exports = {
  english_level: 1,
  "title": {
    "meta": {
      "default_lang": "en"
    },
    "type": "translatable-text",
    "value": null,
    "translations": []
  },
  "level_text": {
    "meta": {
      "default_lang": "en"
    },
    "type": "translatable-text",
    "value": null,
    "translations": []
  },
  "what_can_do_title": {
    "meta": {
      "default_lang": "en"
    },
    "type": "translatable-text",
    "value": null,
    "translations": []
  },
  "what_can_do_content": {
    "meta": {
      "default_lang": "en"
    },
    "type": "translatable-richtext",
    "value": null,
    "translations": []
  },
  "next_step_title": {
    "meta": {
      "default_lang": "en"
    },
    "type": "translatable-text",
    "value": null,
    "translations": []
  },
  "next_step_content": {
    "meta": {
      "default_lang": "en"
    },
    "type": "translatable-richtext",
    "value": null,
    "translations": []
  },
  "min_score": null,
  "max_score": null,
}
