<template>
  <div class="page-content">
    <page-breadcrumb title="Levels" />

    <data-table-ssr id="group_list" ref="group_list"
      :columns="fields" :get-list-fn="getList" :filter_schema="filter_schema"
      :create_schema="create_schema" :create-row-fn="createItem" :item_default_data="defaultObject"
      :to-edit-fn="toEditPage"
    />
  </div>
</template>

<script>
import { buildSelectOptions, safeGet } from '@core/utils/index';

const level_type_opts = [
  { text: "Entry test", value: 1 },
  { text: "Vocabulary", value: 2 },
  { text: "Listening", value: 3 },
  { text: "Speaking", value: 4 },
];
const english_level_opts = [];

const create_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Type', field: 'type', input_type: 'select', options: level_type_opts, validate: { required: true } },
      { label: 'English level', field: 'english_level', input_type: 'select', options: english_level_opts },
      { label: 'Title', field: 'title', input_type: 'ui-component', validate: { required: true } },
      // { label: 'Text', field: 'level_text', input_type: 'ui-component', validate: { required: true } },
      { label: 'Min Score', field: 'min_score', input_type: 'nummer', validate: { required: true } },
      { label: 'Max Score', field: 'max_score', input_type: 'nummer', validate: { required: true } },
      { label: 'What you can do Title', field: 'what_can_do_title', input_type: 'ui-component', validate: { required: true } },
      { label: 'What you can do Content', field: 'what_can_do_content', input_type: 'ui-component', validate: { required: true } },
      { label: 'Next Step Title', field: 'next_step_title', input_type: 'ui-component', validate: { required: true } },
      { label: 'Next Step Content', field: 'next_step_content', input_type: 'ui-component', validate: { required: true } },
    ]
  }
];

const fields = [
  { label: 'Type', field: 'type', input_type: 'select', options: level_type_opts },
  { label: 'English level', field: 'english_level', input_type: 'select', options: english_level_opts },
  { label: 'Title', field: 'title', display_type: 'translatable-text' },
  { label: 'Min Score', field: 'min_score', display_type: 'nummer' },
  { label: 'Max Score', field: 'max_score', display_type: 'nummer' },
  // { label: 'What You Can Do', field: 'what_can_do_content', display_type: 'translatable-richtext'},
  // { label: 'Next Step', field: 'next_step_content', display_type: 'translatable-richtext'},
];

const filter_schema = [
  // {
  //   cols: 3,
  //   fields: [
  //     { label: 'Type', field: 'type', input_type: 'select', options: level_type_opts },
  //   ],
  // },
  {
    cols: 3,
    fields: [
      { label: 'English level', field: 'english_level', input_type: 'select', options: english_level_opts },
    ],
  },
];
import defaultObject from '../defaultObject.js'
import service from '../service'
export default {
  data() {
    return{
      create_schema,
      fields,
      filter_schema,
      defaultObject: JSON.parse(JSON.stringify(defaultObject)),
    };
  },
  methods:{
    async createItem(data) {
      let new_item = await service.create({ ...data });
      return new_item;
    },
    async getList({ limit, page, query }) {
      query.type = 1;
      let response_data = await service.getList({
        query: JSON.stringify(query),
        page, limit
      });
      let list = [], total = 0;
      if (response_data) {
        list = response_data.list;
        total = response_data.total;
        let levels = safeGet(() => buildSelectOptions(response_data.meta['ENGLISH_LEVEL'])) || [];
        english_level_opts.length = 0;
        english_level_opts.push(...levels);
      }
      return { list, total };
    },
    toEditPage({ _id }) {
      this.$router.push({ name: 'exam-level-detail', params: { id: _id } });
    },
  }
}
</script>
